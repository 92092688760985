import { create } from 'zustand';

const KEY = 'cart';

export const useCartStore = create((set) => ({
  items: JSON.parse(localStorage.getItem(KEY)) || [],

  add: (item) =>
    set(({ items }) => {
      localStorage.setItem(KEY, JSON.stringify([...items, item]));
      return { items: [...items, item] };
    }),

  remove: (item) =>
    set(({ items }) => {
      const index = items.findIndex((el) => {
        if (el.name === item.name && el.size === item.size) {
          return true;
        }

        return false;
      });

      items.splice(index, 1);

      localStorage.setItem(KEY, JSON.stringify(items));

      return { items };
    }),

  clear: () =>
    set(() => {
      localStorage.setItem(KEY, JSON.stringify([]));
      return { items: [] };
    }),
}));
