import React, { useEffect, useState } from 'react';

export const CDEKwidget = ({ deliveryChange, isModalOpen }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const getDelivery = (data) => {
    deliveryChange(data);
  };

  useEffect(() => {
    const initCDEK = () => {
      if (window.CDEKWidget && !isInitialized && isModalOpen) {
        const cdekContainer = document.querySelector('#cdek-map');
        if (cdekContainer && !cdekContainer.hasChildNodes()) {
          // Инициализация виджета
          new window.CDEKWidget({
            from: {
              country_code: 'RU',
              city: 'Москва',
              postal_code: 125252,
              code: 44,
              address: 'ул. Куусинена, д. 11к3',
            },
            root: 'cdek-map',
            apiKey: `${process.env.REACT_APP_API_YANDEX_MAP}`,
            canChoose: true,
            servicePath: `${process.env.REACT_APP_API_URL}/cdek-service`,
            hideFilters: {
              have_cashless: false,
              have_cash: false,
              is_dressing_room: false,
              type: false,
            },
            hideDeliveryOptions: {
              office: false,
              door: false,
            },
            debug: false,
            goods: [
              {
                width: 40,
                height: 20,
                length: 60,
                weight: 4000,
              },
            ],
            defaultLocation: 'Москва',
            lang: 'rus',
            currency: 'RUB',
            tariffs: {
              office: [136, 137, 234, 368, 378],
              door: [233, 137],
            },
            onReady() {
              setIsInitialized(true); // Устанавливаем флаг после успешной инициализации
            },
            onChoose(type, tariff, address) {
              const cdekData = {
                type: type === 'door' ? 'До двери' : 'ПВЗ',
                tariff: {
                  sum: tariff.delivery_sum,
                  name: tariff.tariff_name,
                  time: `min ${tariff.calendar_min} d. - max ${tariff.calendar_max} d.`,
                },
                address: {
                  doorAddress: address.formatted || '',
                  region: address.region || '',
                  city: address.city || '',
                  fullAddress: address.address || '',
                  cdekID: address.code || '',
                  postalCode: address.postal_code || '',
                },
              };

              getDelivery(cdekData);
            },
            onCalculate() {},
          });
        }
      }
    };

    if (isModalOpen) {
      initCDEK();
    }

    // Очистка при закрытии модального окна
    return () => {
      if (!isModalOpen) {
        const cdekContainer = document.querySelector('#cdek-map');
        if (cdekContainer) {
          cdekContainer.innerHTML = '';
        }
        setIsInitialized(false);
      }
    };
  }, [isModalOpen, isInitialized]);

  return <div id='cdek-map'></div>;
};
