import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    zIndex: '11',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '0',
    inset: '0',
  },
};

export function SuccessfulOrder() {
  //https://shop.nedostupnost.com/order/accepted?OutSum=11275.00&InvId=319743&SignatureValue=5ef33fa7f87398ca59a9c4f552be4c2b&IsTest=1&Culture=en
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order = searchParams.get('InvId');
  const amount = searchParams.get('OutSum');
  const [isOpen, setIsOpen] = useState(true);
  const Navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
    Navigate('/');
  };

  const openTG = () => {
    window.open('https://t.me/shopnedo', '_blank');
  };

  useEffect(() => {
    document.title = `Заказ: ${order}`;
    return () => {
      document.title = 'NEDOSTUPNOSTЬ SHOP'; // или что-то другое
    };
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='modal'
    >
      <div className='close-modal' onClick={closeModal}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          fill='currentColor'
          className='bi bi-x-lg'
          viewBox='0 0 16 16'
        >
          <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
        </svg>
      </div>

      <div className='shopping-cart'>
        <div className='cart-all'>
          <div className='shopping-cart-title-modal'>Ваш заказ</div>

          <div className='cart-inputs'>
            <div className='input-section'>
              <div className='input-name'>Номер заказа</div>
              <div className='input-info'>{order}</div>
            </div>

            <div className='input-section'>
              <div className='input-name'>Сумма заказа</div>
              <div className='input-info'>{amount} RUB</div>
            </div>

            <div className='input-section'>
              <div className='input-name'>Спасибо за заказ!</div>
              <div className='input-info'>остались вопросы</div>
              <button className='cart-btn-checkout' onClick={openTG}>
                {'\u00A0'}НАПИСАТЬ НАМ{'\u00A0'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
