import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    zIndex: '11',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '0',
    inset: '0',
  },
};

export function DeclinedOrder() {
  const [isOpen, setIsOpen] = useState(true);
  const Navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
    Navigate('/');
  };

  const openTG = () => {
    window.open('https://t.me/shopnedo', '_blank');
  };

  useEffect(() => {
    document.title = `Заказ отклонен`;
    return () => {
      document.title = 'NEDOSTUPNOSTЬ SHOP'; // или что-то другое
    };
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='modal'
    >
      <div className='close-modal' onClick={closeModal}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          fill='currentColor'
          className='bi bi-x-lg'
          viewBox='0 0 16 16'
        >
          <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
        </svg>
      </div>

      <div className='shopping-cart'>
        <div className='cart-all'>
          <div className='shopping-cart-title-modal'>Ваш заказ отклонён</div>
          <div className='cart-inputs'>
            <div className='input-section'>
              <div className='input-name'>Нужна помошь с заказом?</div>
              <div className='input-info'>мы оперативно поможем</div>
              <button className='cart-btn-checkout' onClick={openTG}>
                {'\u00A0'}НАПИСАТЬ НАМ{'\u00A0'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
