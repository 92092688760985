import footerLogo from "./QM_LOGO.png";

export function Footer() {
  return (
    <div className="navigation footer">
      <div
        className="footer-logo"
        style={{
          backgroundImage: `url(${footerLogo})`,
          backgroundPosition: "center",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: "transparent",
        }}
      ></div>
      <div className="nav_item">
        <a href="https://t.me/shopnedo" target="_blank" rel="noreferrer">
          По всем вопросам
        </a>
      </div>
      <div className="nav_item">
        <a
          href="https://vk.com/nedostupnost?w=club72034951"
          target="_blank"
          rel="noreferrer"
        >
          Социальные сети
        </a>
      </div>
      <div className="nav_item">
        <a href="https://shop.nedostupnost.com/policy.html" target="_blank">
          Политика конфиденциальности
        </a>
      </div>
      <div className="nav_item">
        <a href="https://shop.nedostupnost.com/offer.html" target="_blank">
          Публичная оферта
        </a>
      </div>
    </div>
  );
}
